/**
 * @author andreys (Andrew Shustariov)
 */
define(['lodash', 'componentsCore', 'coreUtils', 'santa-components', 'skins', 'documentMedia/skins/skins.json'], function (_, componentsCore, coreUtils, santaComponents, skinsPackage, skinsJson) {
    'use strict';

    const linkRenderer = coreUtils.linkRenderer;

    /**
		  * @class components.DocumentMedia
		  * @extends {core.skinBasedComp}
		  * @property {comp.properties} props
		  */
    function getAltText(compProp, compData) {
        return compProp.showTitle ? compData.title : _.get(compData.link, 'name', '');
    }

    const documentMedia = {
        displayName: 'DocumentMedia',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.skinInfo, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.assign({
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired
        }, santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)),
        getSkinProperties() {
            const compProp = this.props.compProp;
            const compData = this.props.compData;
            compData.alt = getAltText(compProp, compData);

            const linkData = compData.link ? linkRenderer.renderLink(compData.link, this.props.linkRenderInfo, this.props.rootNavigationInfo) : {};
            const skinParams = this.getParams(['contentPaddingLeft', 'contentPaddingRight', 'contentPaddingTop']);

            const wrapperProps = {title: compData.title};
            // adding content-padding to node, so that it'll be accessible from layout
            wrapperProps['data-content-padding-left'] = parseInt(skinParams.contentPaddingLeft.value, 10);
            wrapperProps['data-content-padding-right'] = parseInt(skinParams.contentPaddingRight.value, 10);
            wrapperProps['data-content-padding-top'] = parseInt(skinParams.contentPaddingTop.value, 10);
            wrapperProps['data-content-image-height'] = parseInt(this.props.style.height, 10);

            return {
                '': wrapperProps,
                'img': this.createChildComponent(
                    compData,
                    'core.components.Image',
                    'img',
                    {
                        displayName: 'Image',
                        id: `${this.props.id}img`,
                        ref: 'img',
                        imageData: compData,
                        containerWidth: this.props.style.width,
                        containerHeight: this.props.style.height,
                        displayMode: 'full',
                        'aria-hidden': true
                    }),
                'link': _.assign(linkData, {
                    target: '_blank'
                }),
                'label': {
                    parentConst: santaComponents.utils.createReactElement.bind(null, 'span'),
                    children: compData.title,
                    className: this.classSet({hidden: !compProp.showTitle || _.isEmpty(compData.title)})
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.documentmedia.DocumentMedia', documentMedia);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return documentMedia;
});
