(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['skins.viewer.documentmedia.DocumentMediaSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "img",
        [],
        {}
      ],
      [
        "span",
        "label",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "img": {
      "skin": "skins.core.ImageNewSkin"
    }
  },
  "params": {
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txth": "TEXT_COLOR",
    "brw": "SIZE",
    "contentPaddingLeft": "SIZE",
    "contentPaddingRight": "SIZE",
    "contentPaddingTop": "SIZE"
  },
  "paramsDefaults": {
    "fnt": "font_8",
    "txt": "color_15",
    "txth": "color_13",
    "brw": "5px",
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%": "text-align:center;",
    "%link": "cursor:pointer !important;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%img": "margin:0 auto;overflow:hidden;text-align:left;",
    "%label": "[fnt]  color:[txt];white-space:nowrap;-webkit-transition:color .5s ease 0s;transition:color .5s ease 0s;display:inline-block;",
    "%label%_hidden": "display:none;",
    "%:hover %label": "color:[txth];"
  }
}

        return skins;
    }));